import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import("../pages/home.vue"),
		meta: { title: "中海油融合通讯大屏" },
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("../pages/login.vue"),
		meta: { title: "登录" },
	},
	{
		path: "/meteorology",
		name: "Meteorology",
		component: () => import("../pages/meteorology.vue"),
		meta: { title: "气象日志" },
	},
	{
		path: "/meeting",
		name: "meeting",
		component: () => import("@/pages/MeetingSchedule/index.vue"),
		meta: { title: "会议调度" },
		redirect: { name: "meetingInitiate" },
		children: [
			{
				title: "发起会议",
				path: "/meeting/initiate",
				name: "meetingInitiate",
				component: () => import("@/pages/MeetingSchedule/Initiate.vue"),
				meta: { title: "发起会议" },
			},
			{
				title: "会议中",
				path: "/meeting/ongoing",
				name: "inMeeting",
				component: () =>
					import("@/pages/MeetingSchedule/InMeeting.vue"),
				meta: { title: "会议中" },
			},
		],
	},
	{
		path: "/monitoring",
		name: "monitoring",
		component: () => import("@/pages/MonitoringSchedule/index.vue"),
		meta: { title: "视频监控调度" },
		redirect: { name: "monitoringScreen" },
		children: [
			{
				title: "监控画面",
				path: "/monitoring/screen",
				name: "monitoringScreen",
				component: () =>
					import("@/pages/MonitoringSchedule/Screen.vue"),
				meta: { title: "监控画面" },
			},
			{
				title: "监控详情",
				path: "/monitoring/detail",
				name: "monitoringDetil",
				component: () =>
					import("@/pages/MonitoringSchedule/Detail.vue"),
				meta: { title: "监控画面详情" },
			},
		],
	},
	{
		path: "/notice",
		name: "notice",
		component: () => import("@/pages/NoticeSchedule/index.vue"),
		meta: { title: "通知消息" },
		children: [
			{
				title: "通知消息",
				path: "/notice/initiate",
				name: "noticeInitiate",
				component: () => import("@/pages/NoticeSchedule/Initiate.vue"),
				meta: { title: "通知消息" },
			},
			{
				title: "发布新消息",
				path: "/notice/release",
				name: "noticeRelease",
				component: () => import("@/pages/NoticeSchedule/Release.vue"),
				meta: { title: "发布新消息" },
			},
			{
				title: "消息详情",
				path: "/notice/details",
				name: "noticeDetails",
				component: () => import("@/pages/NoticeSchedule/Details.vue"),
				meta: { title: "消息详情" },
			},
		],
	},
	{
		path: "/notice",
		name: "notice",
		component: () => import("@/pages/NoticeSchedule/index.vue"),
		meta: { title: "通知消息" },
		children: [
			{
				title: "通知消息",
				path: "/notice/initiate",
				name: "noticeInitiate",
				component: () => import("@/pages/NoticeSchedule/Initiate.vue"),
				meta: { title: "通知消息" },
			},
			{
				title: "发布新消息",
				path: "/notice/release",
				name: "noticeRelease",
				component: () => import("@/pages/NoticeSchedule/Release.vue"),
				meta: { title: "发布新消息" },
			},
			{
				title: "消息详情",
				path: "/notice/details",
				name: "noticeDetails",
				component: () => import("@/pages/NoticeSchedule/Details.vue"),
				meta: { title: "消息详情" },
			},
		],
	},
	{
		path: "/eventlog",
		name: "eventlog",
		component: () => import("@/pages/Eventlog/index.vue"),
		meta: { title: "事件日志" },
	},
	{
		path: "/hydrometeor",
		name: "hydrometeor",
		component: () => import("@/pages/Hydrometeor/index.vue"),
		meta: { title: "水文气象" },
	},
	{
		path: "/demo",
		name: "demo",
		component: () => import("@/pages/demo.vue"),
		meta: { title: "事件日志" },
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
