<!--
 * @Author: PC
 * @Date: 2022-01-07 16:59:42
 * @LastEditTime: 2023-03-16 16:53:34
 * @Description: 请您输入文件描述
-->
<template>
  <el-config-provider :locale="locale">
    <router-view />
    <!-- 3D模型 -->
    <ThreeD v-if="!exit3ds && $store.state.token" key="ThreeD" />
    <!-- 告警弹窗 -->
    <AlarmPopup />
  </el-config-provider>
</template>

<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import ThreeD from "components/ThreeD";
import AlarmPopup from "components/AlarmPopup";
import { getCameraList } from "@/api/hydrometeor/index.js";
import { ElConfigProvider } from "element-plus";

export default {
  name: "App",
  watch: {
    $route: {
      handler(route) {
        const token = localStorage.getItem("ACCESS_TOKEN");
        if (!token) {
          this.$router.replace("/login");
        }
        if (token && route.name == "Login") {
          this.$router.replace("/");
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      drag: true,
      locale: zhCn,
      exit3ds: JSON.parse(localStorage.getItem("EXIT_3D")),
    };
  },
  components: {
    ThreeD,
    AlarmPopup,
    ElConfigProvider,
  },
  methods: {
    getCameraList() {
      getCameraList({ page: 1, pageSize: 999 }).then((res) => {
        const { code, data } = res;
        if (code == "B200") {
          data.machineList.map((item) => {
            if (
              item.machineType != 2 &&
              item.configList &&
              item.configList.length < 2
            ) {
              item.configList.push(new Object());
            }
          });
          this.$store.dispatch("setMonitorData", data);
        }
      });
    },
  },
  created() {
    this.$JX.init(() => {
      console.log("JXSDK初始化成功");
      const JX_ACCOUNT = localStorage.getItem("JX_ACCOUNT");
      const JX_PASSWORD = localStorage.getItem("JX_PASSWORD");
      if (JX_ACCOUNT && JX_PASSWORD) this.$JX.login(JX_ACCOUNT, JX_PASSWORD);
    });
    this.getCameraList();
  },
};
</script>
<style lang="scss">
@import "css/reset.css";
@import "css/animate.min.css";
@import "scss/common.scss";
</style>
