/*
 * @Author: ch3nh2
 * @Date: 2021-06-16 16:45:31
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-03-06 17:49:58
 * @FilePath: /zhy_dataview_fe/src/utils/globalData.js
 * @Description: 全局变量(用来记一下状态值)
 */

const globalData = {}

globalData.set = (key, val) => {
  globalData[key] = val
}
globalData.get = (key) => {
  return globalData[key]
}
globalData.del = (key) => {
  delete globalData[key]
}

export default globalData