/*
 * @Author: ch3nh2
 * @Date: 2021-06-09 15:09:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-03-16 16:10:50
 * @FilePath: /zhy_dataview_fe/src/utils/request.js
 * @Description: none
 */
import { config } from "@/config/index";
import utils from "@/utils/index";
import globalData from "@/utils/globalData";
import axios from "axios";
import qs from "qs";

const request = axios.create({
	baseURL: config.baseUrl,
	timeout: 120 * 1000,
	withCredentials: true,
	validateStatus: function (status) {
		return status >= 200 && status < 300;
	},
});
request.defaults.headers = {
	Accept: "application/json",
	"Content-Type": "application/json;charset=utf-8",
};
// request拦截器
request.interceptors.request.use(
	(options) => {
		if (localStorage.getItem("ACCESS_TOKEN")) {
			options.headers["token"] = localStorage.getItem("ACCESS_TOKEN");
		}
		return options;
	},
	(error) => {
		Promise.reject(error);
	}
);
// response 拦截器
request.interceptors.response.use(
	(response) => {
		const token = localStorage.getItem("ACCESS_TOKEN");
		const errorAlert = globalData.get("errorAlert");
		const data = response && response.data;
		config.consoleLog &&
			console.log(
				`【网络请求日志详情】\n请求时间：${new Date().toLocaleString()}\n接口地址：${response.request.responseURL}\n传输数据：`, response.config.data || response.config.params ? { ...response.config.data, ...response.config.params } : '无', `\n接口响应：`, response.data
			);
		if (data && data.code == "B503") {
			token && utils.loginAlert();
			return {};
		}
		if (data && data.code == "B5061") {
			token && utils.errorAlert(
				response.data.msg,
				'温馨提示'
			);
			return {};
		}
		if (data && data.code != "B200") {
			if (data.msg && data.msg.indexOf('no need call sdk interface') != -1) return
			!errorAlert && utils.errorAlert(
				response.data.msg || "服务端出现未知错误",
				`错误代码-${response.data.code || "B-1000"}`
			);
			return {};
		}
		return data;
	},
	(error) => {
		const errorAlert = globalData.get("errorAlert");
		const { message } = error;
		if (message === "Network Error") {
			!errorAlert && utils.errorAlert(
				"当前网络异常或连接到服务器失败",
				"错误代码-B1000"
			);
		} else {
			!errorAlert && utils.errorAlert(
				message || "当前网络异常或连接到服务器失败",
				"错误代码-B1000"
			);
		}
		return Promise.reject(error);
	}
);

export default request;
