/*
 * @Author: Leif
 * @Date: 2021-12-13 09:25:49
 * @LastEditTime: 2022-08-23 13:39:39
 * @LastEditors: ch3nh2
 * @Description:
 */
import { createStore } from "vuex";

export default createStore({
  state: {
    token: localStorage.getItem("ACCESS_TOKEN") || null,
    userInfo: (localStorage.getItem("USER_INFO") && JSON.parse(localStorage.getItem("USER_INFO"))) || null,
    //话机状态
    telePhoneStatus: false,
    // 对讲机状态
    intercomStatus: false,
    //广播区域
    broadcastArea: [],
    // 监控设备数据
    monitordata: {
      cameraList: [],
      machineList: [],
    },
    //监控画面列表
    monitorList: [],
    // 单呼号码类型
    singleCallName: '',
    // 单呼号码
    singleCallNumber: null,
  },
  mutations: {
    SET_TOKEN(state, data) {
      state.token = data;
    },
    SET_TEL_PHONE_STATE(state, data) {
      state.telePhoneStatus = data;
    },
    SET_INTERCOM_STATE(state, data) {
      state.intercomStatus = data;
    },
    SET_BROADCAST_AREA(state, data) {
      state.broadcastArea = data;
    },
    SET_MONITORDATA(state, data) {
      const { cameraList, machineList } = data || {};
      const monitordata = {
        cameraList: cameraList || [],
        machineList: machineList || [],
      };
      state.monitordata = monitordata;
    },
    SET_MONITOR_LIST(state, data) {
      state.monitorList = data;
    },
    SET_SINGLECALLNAME(state, data) {
      state.singleCallName = data;
    },
    SET_SINGLECALLNUMBER(state, data) {
      state.singleCallNumber = data;
    }
  },
  actions: {
    setToken({ state, commit, rootState }, params) {
      commit("SET_TOKEN", params);
    },
    setTelPhoneState({ state, commit, rootState }, params) {
      commit("SET_TEL_PHONE_STATE", params);
    },
    setIntercomState({ state, commit, rootState }, params) {
      commit("SET_INTERCOM_STATE", params);
    },
    setBroadcastArea({ state, commit, rootState }, params) {
      commit("SET_BROADCAST_AREA", params);
    },
    setMonitorData({ state, commit, rootState }, params) {
      commit("SET_MONITORDATA", params);
    },
    setMonitorList({ state, commit, rootState }, params) {
      commit("SET_MONITOR_LIST", params);
    },
    setSingleCallName({ state, commit, rootState }, params) {
      commit("SET_SINGLECALLNAME", params);
    },
    setSingleCallNumber({ state, commit, rootState }, params) {
      commit("SET_SINGLECALLNUMBER", params);
    },
  },
  modules: {},
});
