<!--
 * @Author: ch3nh2
 * @Date: 2021-10-25 18:26:15
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-03-06 20:00:40
 * @Description: 监控视频画面播放
-->
<template>
  <div class="video_player" element-loading-background="#2a2e3a7a" element-loading-spinner="el-icon-loading"
    v-loading="loadRefs === 'LOADING'" :element-loading-text="ERROR_INFO[loadRefs]">
    <div class="video_error" v-show="loadRefs && loadRefs != 'LOADING'">
      <template v-if="loadRefs === 'TEST'">
        <img :src="
          type == 'img'
            ? require('../../assets/image/cover/cover-9.jpg')
            : require(`../../assets/image/cover/cover-${coverNum}.jpg`)
        " />
      </template>
      <template v-else>
        <p>{{ ERROR_INFO[loadRefs] }}</p>
        <el-button v-if="loadRefs == 'OFFLINE' || loadRefs == 'UNKNOWN'" type="text"
          style="min-height: 0; padding-bottom: 0" @click="getStream(type, source)">点击重新加载</el-button>
      </template>
    </div>
    <video v-show="!loadRefs" class="video_item" ref="videoRefs" autoplay muted />
  </div>
</template>

<script>
const importJs = {
  Hls: require("hls.js"),
  Flv: require("flv.js"),
};
import { config } from "@/config/index";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import utils from "../../utils";
export default {
  name: "VideoPlayer",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "Flv",
    },
    source: {
      type: String,
      default: "",
    },
    isFullScreen: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const ERROR_INFO = {
      UNKNOWN: "载入画面错误",
      LOADING: "正在加载画面",
      OFFLINE: "设备暂不在线",
      NO_URL: "当前暂无画面",
      ON_CLOSE: "当前已关闭画面",
      NOT_SUPPORT: "不支持该协议",
    };
    const hlsPlayer = {};
    const flvPlayer = {};
    const webRtcPlayer = {};
    const loadRefs = ref();
    const videoRefs = ref();
    const playerRefs = ref();
    const offerRefs = ref();
    const webrtcRefs = ref();
    const sendChannel = ref();
    const sendInterval = ref();

    const coverNum = computed(() => {
      return utils.randomNum(0, 8);
    });

    /**
     * HLS方案加载并播放
     */
    hlsPlayer.load = (type, source) => {
      const isSupported = importJs[type].isSupported();
      if (!isSupported) {
        loadRefs.value = "NOT_SUPPORT";
        return;
      }
      loadRefs.value = "LOADING";
      playerRefs.value = new importJs[type]();
      playerRefs.value.loadSource(source);
      playerRefs.value.attachMedia(videoRefs.value);
      playerRefs.value.on(importJs[type].Events.MANIFEST_PARSED, () => {
        videoRefs.value.play();
      });
      playerRefs.value.on(importJs[type].Events.ERROR, (event, data) => {
        loadRefs.value = "UNKNOWN";
        videoRefs.value.pause();
      });
      videoRefs.value.oncanplay = () => {
        onCanplay();
      };
    };

    /**
     * FLV方案加载并播放
     */
    flvPlayer.load = (type, source) => {
      const isSupported = importJs[type].isSupported();
      if (!isSupported) {
        loadRefs.value = "NOT_SUPPORT";
        return;
      }
      loadRefs.value = "LOADING";
      playerRefs.value = importJs[type].createPlayer({
        url: source,
        type: "flv",
        isLive: true,
        hasAudio: false,
      });
      playerRefs.value.attachMediaElement(videoRefs.value);
      playerRefs.value.load();
      playerRefs.value.on(importJs[type].Events.LOADING_COMPLETE, () => {
        videoRefs.value.play();
      });
      playerRefs.value.on(importJs[type].Events.ERROR, (event, data) => {
        loadRefs.value = "UNKNOWN";
        // if (playerRefs.value) {
        //   videoPause(props.type);
        //   getStream(props.type, props.source);
        // }
      });
      videoRefs.value.oncanplay = () => {
        onCanplay();
      };
    };

    /**
     * WebRtc方案加载并播放
     */
    webRtcPlayer.load = (source) => {
      loadRefs.value = "LOADING";
      webrtcRefs.value = new RTCPeerConnection({
        sdpSemantics: "unified-plan",
      });
      webrtcRefs.value.onnegotiationneeded = async () => {
        offerRefs.value = await webrtcRefs.value.createOffer({
          offerToReceiveVideo: true,
        });
        await webrtcRefs.value.setLocalDescription(offerRefs.value);
        try {
          const response = await fetch(source, {
            method: "POST",
            body: JSON.stringify(webrtcRefs.value.localDescription),
          });
          const data = await response.json();
          if (data && !data.errmsg) {
            try {
              webrtcRefs.value.setRemoteDescription(
                new RTCSessionDescription(data)
              );
            } catch (e) {
              loadRefs.value = "UNKNOWN";
            }
          } else {
            loadRefs.value = "OFFLINE";
          }
        } catch (e) {
          loadRefs.value = "OFFLINE";
        }
      };
      webrtcRefs.value.ontrack = function (event) {
        videoRefs.value.srcObject = event.streams[0];
        videoRefs.value.autoplay = true;
        videoRefs.value.play();
      };
      videoRefs.value.oncanplay = onCanplay;
      sendChannel.value = webrtcRefs.value.createDataChannel("sendchannel");
      sendChannel.value.onclose = () => {
        sendInterval.value && clearInterval(sendInterval.value);
        // getStream('WebRtc', source)
      };
      sendChannel.value.onopen = () => {
        sendChannel.value.send("ping");
        sendInterval.value = setInterval(() => {
          if (
            (sendChannel.value && sendChannel.value.send) ||
            (sendChannel.value && sendChannel.value.readyState === "open")
          ) {
            sendChannel.value.send("ping");
          } else {
            clearInterval(sendInterval.value);
            // getStream('WebRtc', source)
          }
        }, 1 * 1000);
      };
      sendChannel.value.onmessage = (e) => console.log(e.data);
    };

    // 获取视频并播放
    const getStream = (type, source) => {
      /** 测试环境展示star */
      if (config.dev || config.sit) {
        loadRefs.value = "LOADING";
        setTimeout(() => {
          loadRefs.value = "TEST";
        }, 3000);
        return;
      }
      /** 测试环境展示end */

      if (!source) {
        loadRefs.value = "NO_URL";
        return;
      }
      switch (type) {
        case "Hls":
          hlsPlayer.load(type, source);
          break;
        case "Flv":
          flvPlayer.load(type, source);
          break;
        case "WebRtc":
          webRtcPlayer.load(source);
          break;
        default:
          break;
      }
      config.consoleLog && console.log(`视频流采用${type}方案播放`);
    };

    // 播放成功回调
    const onCanplay = () => {
      loadRefs.value = undefined;
    };

    // 停止视频并销毁
    const videoPause = (type) => {
      if (type === "WebRtc") {
        if (webrtcRefs.value && sendChannel.value) {
          videoRefs.value.pause();
          webrtcRefs.value.close();
          sendChannel.value.close();
          offerRefs.value = null;
          webrtcRefs.value = null;
          sendChannel.value = null;
        }
      } else {
        if (playerRefs.value) {
          videoRefs.value.pause();
          playerRefs.value.destroy();
          playerRefs.value = null;
        }
      }
      config.consoleLog && console.log(type + "视频流方案已停止播放并销毁");
      loadRefs.value = "ON_CLOSE";
    };

    // 双击进入全屏
    const onFullScreen = () => {
      const { isFullScreen } = props;
      if (isFullScreen && videoRefs.value && !loadRefs.value) {
        videoRefs.value.webkitRequestFullScreen();
      }
    };

    onMounted(() => {
      getStream(props.type, props.source);
    });

    onBeforeUnmount(() => {
      videoPause(props.type);
    });

    return {
      coverNum,
      loadRefs,
      videoRefs,
      getStream,
      videoPause,
      onCanplay,
      onFullScreen,
      webrtcRefs,
      ERROR_INFO,
    };
  },
};
</script>

<style lang="scss" scoped>
.video_player {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .video_error {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2a2e3a7a;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    p {
      color: #999999;
    }
  }

  .video_item {
    width: 100%;
    height: 100%;
    object-fit: fill;
    pointer-events: none;
  }
}
</style>
