/*
 * @Author: ch3nh2
 * @Date: 2021-06-03 14:43:57
 * @LastEditors: ch3nh2
 * @LastEditTime: 2021-10-26 18:43:06
 * @FilePath: /zhy_dataview_fe/src/plugins/element.js
 * @Description: none
 */
import ElementPlus from 'element-plus';
import '@/scss/element.scss'
import "@/assets/font/element/style.css";

export default (app) => {
  app.use(ElementPlus)
}