/*
 * @Author: ch3nh2
 * @Date: 2021-06-10 15:27:38
 * @LastEditors: ch3nh2
 * @LastEditTime: 2021-10-21 19:20:29
 * @FilePath: /zhy_dataview_fe/src/plugins/utils.js
 * @Description: none
 */
import utils from '@/utils/index';

export default (app) => {
  app.config.globalProperties.$utils = utils
}