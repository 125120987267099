import QWebChannel from './qwebchannel';

class JX {
  /**
   * 初始化控件
   */
  init(callback) {
    'use strict';
    var wsUri = "ws://localhost:12345";
    window.loggedin = false;
    window.isWebCallInit = false;

    var socket = new WebSocket(wsUri);

    socket.onclose = function () {
      console.error("web channel closed");
      window.isWebCallInit = false;
    };
    socket.onerror = function (error) {
      console.error("web channel error: " + error);
      window.isWebCallInit = false;
    };
    socket.onopen = function () {
      window.channel = new QWebChannel(socket, function (channel) {
        channel.objects.ocxServer.initOCX();
        window.isWebCallInit = true;
        callback && callback();
        if (typeof channel.objects.ocxServer.newMessage !== "undefined") {
          /**
           * 各种返回值说明
           * 点呼状态："type": "singleCallStatus", "status": "idle|ring|ringBack|talking|localHold|remoteHold|inactive" 
           * idle:释放，ring:响铃，ringBack:回铃，localHold:本地保持，remoteHold:远端保持，inactive:双向保持
           */
          channel.objects.ocxServer.newMessage.connect(function (msg) {
            window.dispatchEvent(new CustomEvent('onMessageQT', {
              detail: msg && JSON.parse(msg)
            }))
          });
        }
      });
    };
  }

  /**
  * @brief: 登录注册
  * @param: strUserName,登录账号
  * @param: strPassword,密码
  */
  login(strUserName, strPassword) {
    if (window.isWebCallInit && strUserName && strPassword) {
      window.channel.objects.ocxServer.login(strUserName, strPassword);
    } else {
      console.error("web call uninited");
    }
  }

  /**
  * @brief: 注销登出
  */
  logout() {
    if (window.isWebCallInit) {
      window.channel.objects.ocxServer.logout();
    } else {
      console.error("web call uninited");
    }
  }

  /**
  * @brief: 退出软件
  */
  appQuit() {
    if (window.isWebCallInit) {
      window.channel.objects.ocxServer.appQuit();
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 语音点呼
   * @param: strCalleeName,被叫名称
   * @param: strCallNumber,被叫号码
   */
  callOut(strCalleeName, strCalleeNumber) {
    if (window.isWebCallInit && strCalleeNumber) {
      if (strCalleeName.trim() === "") {
        strCalleeName = strCalleeNumber;
      }
      window.channel.objects.ocxServer.vCallOut(strCalleeName, strCalleeNumber, 0);
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 视频点呼
   * @param: strCalleeName,被叫名称
   * @param: strCallNumber,被叫号码
   */
  videoCallOut(strCalleeName, strCalleeNumber) {
    if (window.isWebCallInit && strCalleeNumber) {
      if (strCalleeName.trim() === "") {
        strCalleeName = strCalleeNumber;
      }
      window.channel.objects.ocxServer.vCallOut(strCalleeName, strCalleeNumber, 1);
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 调监控
   * @param: strCameraName,监控摄像头名称
   * @param: strCameraNumber,监控摄像头号码
   */
  vLivePlay(strCameraName, strCameraNumber) {
    if (window.isWebCallInit && strCameraNumber) {
      if (strCameraName.trim() === "") {
        strCameraName = strCameraNumber;
      }
      window.channel.objects.ocxServer.vLivePlay(strCameraName, strCameraNumber);
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 发起会议
   * @param: confUserLst,会议成员列表
   * @param: ulVideoPull,是否支持视频
   */
  vStartConference(confUserLst, ulVideoPull) {
    if (window.isWebCallInit && confUserLst) {
      ulVideoPull = ulVideoPull === '1' ? ulVideoPull : '0';
      window.channel.objects.ocxServer.vStartConference(confUserLst, ulVideoPull);
    } else {
      console.error("web call uninited");
    }
  }

  /**
  * @brief: 添加会议成员
  * @param: strUserNumber,会议成员号码
  */
  joinUser(strUserNumber) {
    if (window.isWebCallInit && strUserNumber) {
      window.channel.objects.ocxServer.vJoinUser(strUserNumber);
    } else {
      console.error("web call uninited");
    }
  }

  /**
  * @brief: 添加会议成员
  * @param: strUserName,会议成员名称
  * @param: strUserNumber,会议成员号码
  */
  conferenceJoinUser(strUserName, strUserNumber) {
    if (window.isWebCallInit && strUserNumber) {
      if (strUserName.trim() === "") {
        strUserName = strUserNumber;
      }
      window.channel.objects.ocxServer.vConferenceJoinUser(strUserName, strUserNumber);
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 显示录音、录像窗口
   */
  showAVRecordWindow() {
    if (window.isWebCallInit) {
      window.channel.objects.ocxServer.vShowAVRecordWindow();
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 显示控件窗口
   * @param: show,是否显示窗口，1显示，0隐藏
   */
  showWindow(show) {
    if (window.isWebCallInit) {
      window.channel.objects.ocxServer.vShowWindow(show);
    } else {
      console.error("web call uninited");
    }
  }

  /**
  * @brief: 发起环境监视监听
  @param: strCallNumber,被叫号码
  */
  monitorEnvCall(strCameraNumber) {
    if (window.isWebCallInit && strCameraNumber) {
      window.channel.objects.ocxServer.monitorEnvCall(strCameraNumber);
    } else {
      console.error("web call uninited");
    }
  }

  /**
  * @brief: 视频上拉
    @param: strCalleeName,被叫名称
    @param: strCallNumber,被叫号码
  */
  pullonVideoCall(strCalleeName, strCallNumber) {
    if (window.isWebCallInit && strCallNumber) {
      if (strCalleeName.trim() === "") {
        strCalleeName = strCallNumber;
      }
      window.channel.objects.ocxServer.pullonVideoCall(strCalleeName, strCallNumber);
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 开启远程录制
     @param: strCallNumber,被叫号码
   */
  startRemoteRecord(strCallNumber) {
    if (window.isWebCallInit && strCallNumber) {
      window.channel.objects.ocxServer.startRemoteRecord(strCallNumber);
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 停止远程录制
     @param: strCallNumber,被叫号码
   */
  stopRemoteRecord(strCallNumber) {
    if (window.isWebCallInit && strCallNumber) {
      window.channel.objects.ocxServer.pullonVideoCall(strCallNumber);
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 显示拨号盘
   */
  showDialKeyboard() {
    if (window.isWebCallInit) {
      window.channel.objects.ocxServer.showDialKeyboard();
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 发起ptt组呼
   @param: strPTTGrpName,Ptt组呼名称
   @param: strPTTGroupID,ptt组呼号码
   */
  startPTTGroup(strPTTGrpName, strPTTGroupID) {
    if (window.isWebCallInit && strPTTGroupID) {
      if (strPTTGrpName.trim() === "") {
        strPTTGrpName = strPTTGroupID;
      }
      window.channel.objects.ocxServer.vStartPTTGroup(strPTTGrpName, strPTTGroupID);
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 发起临时ptt组呼
   @param: strPTTGrpName,Ptt组呼名称
   @param: strPTTGrpUserNoList,ptt组呼成员号码列表,成员号码以分号结束
   */
  startTempPTTGroup(strPTTGrpName, strPTTGrpUserNoList) {
    if (window.isWebCallInit && strPTTGrpUserNoList) {
      window.channel.objects.ocxServer.vStartTempPTTGroup(strPTTGrpName, strPTTGrpUserNoList);
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 结束ptt组呼
   @param: strPTTGroupID,ptt组呼号码
   */
  releasePTTGroup(strPTTGroupID) {
    if (window.isWebCallInit && strPTTGroupID) {
      window.channel.objects.ocxServer.vReleasePTTGroup(strPTTGroupID);
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 添加成员到ptt组呼
   @param: strCallName,成员名称
   @param: strCallNumber,成员号码
   */
  groupJoinUser(strCallName, strCallNumber) {
    if (window.isWebCallInit && strCallNumber) {
      if (strCallName.trim() === "") {
        strCallName = strCallNumber;
      }
      window.channel.objects.ocxServer.vGroupJoinUser(strCallName, strCallNumber);
    } else {
      console.error("web call uninited");
    }
  }

  /**
  * @brief: 设置视频窗口显示
  */
  setVideoShow() {
    if (window.isWebCallInit) {
      window.channel.objects.ocxServer.setVideoShow();
    } else {
      console.error("web call uninited");
    }
  }

  /**
  * @brief: 申请集群话权
  @param: strPTTGroupID,ptt组呼号码
  */
  commSpeakingPTTGroup(strPTTGroupID) {
    if (window.isWebCallInit && strPTTGroupID) {
      window.channel.objects.ocxServer.commSpeakingPTTGroup(strPTTGroupID);
    } else {
      console.error("web call uninited");
    }
  }

  /**
  * @brief: 释放集群话权
  @param: strPTTGroupID,ptt组呼号码
  */
  commEndSpeakingPTTGroup(strPTTGroupID) {
    if (window.isWebCallInit && strPTTGroupID) {
      window.channel.objects.ocxServer.commEndSpeakingPTTGroup(strPTTGroupID);
    } else {
      console.error("web call uninited");
    }
  }

  /**
  * @brief: 最小化窗口
  */
  appMixmum() {
    if (window.isWebCallInit) {
      window.channel.objects.ocxServer.appMixmum();
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 接入广播(也可用于单呼)
   * @param: strCalleeName,被叫名称
   * @param: strCallNumber,被叫号码
   * @param: bPopup, true：弹出呼叫控件界面， false：不弹出呼叫控制界面
   */
  callOutToBroadcast(strCalleeName, strCalleeNumber, bPopup) {
    if (window.isWebCallInit && strCalleeNumber) {
      if (strCalleeName.trim() === "") {
        strCalleeName = strCalleeNumber;
      }
      window.channel.objects.ocxServer.callOutToBroadcastDevice(strCalleeName, strCalleeNumber, bPopup);
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 停止接入广播(也可用于停止单呼)
   * @param: strCallNumber,被叫号码
   */
  releaseToBroadcast(strCalleeNumber) {
    if (window.isWebCallInit && strCalleeNumber) {
      window.channel.objects.ocxServer.releaseToBroadcastDevice(strCalleeNumber);
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 点呼静音控制接口（适用于点呼、接入广播）
   * @param: strCallNumber,被叫号码
   * @param: bMute,true：静音，false：关闭静音
   */
  callMicMuteCtrl(strCalleeNumber, bMute) {
    if (window.isWebCallInit && strCalleeNumber) {
      window.channel.objects.ocxServer.callMuteCtrl(strCalleeNumber, bMute, function (bSuccess) {
        if (true == bSuccess) {
          //mic静音成功处理
        } else {
          //mic静音成功处理
        }
      });
    } else {
      console.error("web call uninited");
    }
  }

  /**
   * @brief: 点呼二次拨号（适用于点呼、接入广播）
   * @param: strCallNumber, 被叫号码
   * @param: chDtmf, 二次拨号号码
   */
  callSecondDial(strCalleeNumber, chDtmf) {
    if (window.isWebCallInit && strCalleeNumber) {
      window.channel.objects.ocxServer.callSecondDial(strCalleeNumber, chDtmf, function (bSuccess) {
        if (true == bSuccess) {
          //成功处理
        } else {
          //失败处理
        }
      });
    } else {
      console.error("web call uninited");
    }
  }
}

export default new JX