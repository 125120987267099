/*
 * @Author: ch3nh2
 * @Date: 2021-06-08 11:22:49
 * @LastEditors: ch3nh2
 * @LastEditTime: 2021-10-21 19:20:07
 * @FilePath: /zhy_dataview_fe/src/plugins/apexcharts.js
 * @Description: none
 */
import VueApexCharts from "vue3-apexcharts";

const zh = {
  "name": "zh-cn",
  "options": {
    "months": [
      "一月",
      "二月",
      "三月",
      "四月",
      "五月",
      "六月",
      "七月",
      "八月",
      "九月",
      "十月",
      "十一月",
      "十二月"
    ],
    "shortMonths": [
      "一月",
      "二月",
      "三月",
      "四月",
      "五月",
      "六月",
      "七月",
      "八月",
      "九月",
      "十月",
      "十一月",
      "十二月"
    ],
    "days": [
      "星期天",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六"
    ],
    "shortDays": ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
    "toolbar": {
      "exportToSVG": "下载 SVG",
      "exportToPNG": "下载 PNG",
      "exportToCSV": "下载 CSV",
      "menu": "菜单",
      "selection": "选择",
      "selectionZoom": "选择缩放",
      "zoomIn": "放大",
      "zoomOut": "缩小",
      "pan": "平移",
      "reset": "重置缩放"
    }
  }
}

Apex.chart = {
  locales: [zh],
  defaultLocale: "zh-cn",
  foreColor: "#ffffff",
  animations: {
    enabled: true,
    easing: "linear",
    dynamicAnimation: {
      speed: 1000
    }
  },
  toolbar: {
    tools: {
      zoom: false
    },
    autoSelected: 'pan'
  },
  theme: {
    monochrome: {
      enabled: true,
      color: '#000000',
      shadeIntensity: 1
    },
  }
}

export default (app) => {
  app.use(VueApexCharts);
  app.config.globalProperties.$apexcharts = VueApexCharts;
}