/*
 * @Author: ch3nh2
 * @Date: 2022-06-22 13:43:43
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-06-29 11:48:27
 * @FilePath: /zhy_dataview_fe/src/plugins/jiaxun.js
 * @Description: none
 */

import JX from "@/utils/JXCall";

export default (app) => {
  app.config.globalProperties.$JX = JX
}