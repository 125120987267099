<!--
 * @Author: Leif
 * @Date: 2021-12-13 09:37:52
 * @LastEditTime: 2022-09-06 19:43:14
 * @LastEditors: ch3nh2
 * @Description: 告警弹窗
-->
<template>
  <div class="alarm_popup" v-for="(item, index) in alarmArr" v-show="item.alarmVisible" :key="index">
    <div class="alarm_popup_item" v-if="item.alarmVisible">
      <div class="alarm_title">
        <i class="icon_alarm_title"></i>
        提示：已触发“{{ item.dataJson.title }}”（第{{ index + 1 }}条/共{{
        alarmArr.length
        }}条）
      </div>
      <div class="alarm_popup_inner">
        <div class="block" v-for="(mitem, mindex) in item.dataJson.monitorMsg.monitorAddressList"
          v-if="item.dataJson.enableMonitor">
          <div class="alarm_label_row">
            <div class="alarm_label_title">
              <i class="icon_alarm"></i>
              <span>已调取{{ mitem.name }}监控</span>
            </div>
          </div>
          <div class="monitoring_area">
            <VideoPlayer :source="mitem.webRtcUrl ? mitem.webRtcUrl : ''" />
          </div>
        </div>

        <div class="block">
          <div class="alarm_label_row">
            <div class="alarm_label_title">
              <i class="icon_alarm"></i>
              <span>是否向以下区域发送广播通知内容</span>
            </div>
            <div class="alarm_radio">
              <el-radio v-model="item.dataJson.enableSendBroadcastMsg" :label="true">
                是
              </el-radio>
              <el-radio v-model="item.dataJson.enableSendBroadcastMsg" :label="false">
                否
              </el-radio>
            </div>
          </div>
          <template v-if="item.dataJson.enableSendBroadcastMsg">
            <div class="alarm_select_area">
              <el-select multiple collapse-tags v-if="!item.dataJson.broadcastMsg.isGlobalBroadcast"
                v-model="item.dataJson.broadcastMsg.notifyIdList">
                <el-option v-for="(bitem, index) in broadcastArea" :key="bitem.areaValue" :value="bitem.areaCode"
                  :label="bitem.areaName">
                </el-option>
              </el-select>
              <el-switch v-model="item.dataJson.broadcastMsg.isGlobalBroadcast" style="margin-left: 20px" size="large"
                inactive-text="全区域" />
            </div>
            <div class="alarm_textarea">
              <el-input class="custom_textarea" type="textarea" placeholder="暂无内容"
                v-model="item.dataJson.broadcastMsg.content" :autosize="{ minRows: 4, maxRows: 6 }"
                :disabled="!isTextarea(item.alertType)" />
            </div>
          </template>
        </div>

        <div class="block">
          <div class="alarm_label_row">
            <div class="alarm_label_title">
              <i class="icon_alarm"></i><span>是否向以下人员发送通知消息</span>
            </div>
            <div class="alarm_radio">
              <el-radio disabled v-model="item.dataJson.enableSendNoticeMsg" :label="true">
                是
              </el-radio>
              <el-radio disabled v-model="item.dataJson.enableSendNoticeMsg" :label="false">
                否
              </el-radio>
            </div>
          </div>
          <template v-if="item.dataJson.enableSendNoticeMsg">
            <div class="people_list">
              <div class="people_item" v-for="(nitem, nindex) in findTree(
                userList,
                item.dataJson.noticeMsg.notifyIdList
              )" :key="index">
                <div class="avatar">
                  <img :src="require('@/assets/icons/icon_group.png')" alt="" />
                </div>
                <div class="people_cell">
                  <div class="people_name">
                    {{ nitem.nickname }}
                  </div>
                  <div class="people_phone">{{ nitem.userName }}</div>
                </div>
              </div>
            </div>
            <div class="alarm_textarea">
              <el-input class="custom_textarea" type="textarea" placeholder="暂无内容"
                v-model="item.dataJson.noticeMsg.content" :autosize="{ minRows: 4, maxRows: 6 }"
                :disabled="!isTextarea(item.alertType)" />
            </div>
          </template>
        </div>

        <div class="block">
          <div class="alarm_label_row">
            <div class="alarm_label_title">
              <i class="icon_alarm"></i><span>是否呼叫联系人</span>
            </div>
            <div class="alarm_radio">
              <el-radio disabled v-model="item.dataJson.enableCallingContacts" :label="true">
                是
              </el-radio>
              <el-radio disabled v-model="item.dataJson.enableCallingContacts" :label="false">
                否
              </el-radio>
            </div>
          </div>
          <template v-if="item.dataJson.enableCallingContacts">
            <div class="people_list">
              <div class="people_item" v-for="(item, index) in findTree(
                userContact,
                item.dataJson.callingContactsMsg.notifyIdList
              )" :key="index">
                <div class="avatar">
                  <img :src="require('@/assets/icons/icon_group.png')" alt="" />
                </div>
                <div class="people_cell">
                  <div class="people_name">
                    {{ item.name }}
                  </div>
                  <div class="people_phone">{{ item.numberInfo.length > 0 ? item.numberInfo[0].userNumber : item.account
                  }}</div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="tool_row">
        <el-button class="tool_btn tool_comfirn" @click="confirmAlarm(item, index)" type="primary">
          确定
        </el-button>
        <el-button class="tool_btn tool_just" @click="removeWarnAlarm(item, index)">
          忽略此处告警
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "./InsidePages/VideoPlayer";
import {
  getLinkagePopup,
  postConfirmWarn,
  removeWarn,
} from "@/api/hydrometeor/index.js";
const keys = ["monitorMsg", "noticeMsg", "broadcastMsg", "callingContactsMsg"];
export default {
  name: "InviteNotification",
  watch: {
    $route: {
      handler(route) {
        if (route.name == "Home") {
          this.getLinkagePopup();
        } else {
          this.clearTimeout();
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      broadcastArea: [],
      userList: [],
      userContact: [],
      alarmArr: [],
      popupTimer: null,
    };
  },
  computed: {
    findTree() {
      const treeFindOids = this.$utils.treeFindOids;
      return function (tree, ids) {
        return treeFindOids(tree, ids);
      };
    },
    isTextarea() {
      const keys = {
        MONITOR: false,
        INFRARED: false,
        ANTI_BOARDING: false,
        METEOROLOGICAL: true,
        METEOROLOGICAL_LOCAL: true,
      };
      return function (type) {
        return keys[type];
      };
    },
  },
  components: {
    VideoPlayer,
  },
  methods: {
    getAreaIds() {
      this.$api.getAreaIds().then((res) => {
        const { code, data } = res;
        if (code == "B200") {
          const options = data.filter((item) => item.areaValue != -1);
          this.broadcastArea = options;
        }
      });
    },
    getUserList() {
      this.$api.userList().then((res) => {
        const { code, data } = res;
        if (code == 'B200') {
          this.userList = data
        }
      })
    },
    getTreeContacts() {
      this.$api.getTreeContacts().then((res) => {
        const { code, data } = res
        if (code == 'B200') {
          this.userContact = data.jiaXunContacts
        }
      });
    },
    getLinkagePopup() {
      getLinkagePopup({
        reviewStatus: "NOT_REVIEWED",
      }).then((res) => {
        const { code, data } = res;
        if (code == "B200" && data.length > 0) {
          data.map((item) => {
            item.dataJson = item.dataJson && JSON.parse(item.dataJson);
            item.alarmVisible = true;
            keys.map((key) => {
              if (item.dataJson[key]) {
                const notifyIdList = item.dataJson[key].notifyIdList;
                item.dataJson[key].notifyIdList = JSON.parse(notifyIdList);
              } else {
                item.dataJson[key] = {
                  content: "",
                  notifyIdList: [],
                  monitorAddressList: [],
                  isGlobalBroadcast: false,
                };
              }
            });
          });
          this.alarmArr = data;
          this.clearTimeout(false);
          return;
        }
        this.popupTimer = setTimeout(() => {
          this.getLinkagePopup();
        }, 10 * 1000);
      });
    },
    confirmAlarm(item, index) {
      const { id, dataJson } = item;
      let callItem;
      keys.map((key) => {
        dataJson[key].notifyIdList = JSON.stringify(dataJson[key].notifyIdList);
        if (key == 'callingContactsMsg') {
          callItem = this.$utils.treeFindOids(this.userContact, dataJson[key].notifyIdList)[0];
        }
      });
      postConfirmWarn({ id, dataJson: JSON.stringify(dataJson) }).then(() => {
        if (callItem) {
          const callName = callItem.name;
          const callAccount = callItem.numberInfo.length > 0 ? callItem.numberInfo[0].userNumber : callItem.account
          this.$JX.callOut(callName, callAccount);
        }
        this.hideAlarm(index);
        this.$message({
          message: "操作成功",
          type: "success",
          duration: 1500,
          onClose: () => {
            this.getLinkagePopup();
          },
        });
      });
    },
    removeWarnAlarm(item, index) {
      const { id } = item;
      removeWarn(id).then(() => {
        this.getLinkagePopup();
        this.hideAlarm(index);
      });
    },
    hideAlarm(index) {
      this.alarmArr[index].alarmVisible = false;
    },
    clearTimeout(isClose = true) {
      clearTimeout(this.popupTimer);
      this.popupTimer = null;
      this.$config.consoleLog && console.log("已清除告警弹窗轮询定时器");
      isClose && this.alarmArr.map((item) => (item.alarmVisible = false));
    },
  },
  created() {
    this.getAreaIds();
    this.getUserList()
    this.getTreeContacts();
  },
  beforeUnmount() {
    this.clearTimeout();
  },
};
</script>

<style lang="scss">
.alarm_popup {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(0, 0, 0, 0.08);

  .alarm_popup_item {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 800px;
    transform: translate(-50%, -50%);
    background: rgba(24, 24, 37);
  }

  .close_popup {
    width: 26px;
    height: 26px;
    background: url(../assets/icons/close_icon@2x.png) no-repeat;
    background-size: 100%;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
    cursor: pointer;
  }

  .icon_alarm {
    width: 26px;
    height: 26px;
    background: url(../assets/icons/warning_icon@2x.png) no-repeat;
    display: inline-block;
    background-size: 100%;
    margin-right: 10px;
    position: relative;
    top: 4px;
  }

  .alarm_title {
    height: 70px;
    background: #2a2e3a;
    color: rgba(240, 88, 88, 1);
    font-size: 22px;
    line-height: 70px;
    padding: 0 20px;

    .icon_alarm_title {
      width: 26px;
      height: 26px;
      background: url(../assets/icons/Tips_icon@2x.png) no-repeat;
      display: inline-block;
      background-size: 100%;
      margin-right: 10px;
      position: relative;
      top: 4px;
    }
  }

  .block {
    margin-bottom: 30px;
  }

  .alarm_popup_inner {
    padding: 40px;
    max-height: 800px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .alarm_label_title {
    width: 380px;
  }

  .monitoring_area,
  .alarm_label_row,
  .alarm_select_area {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .monitoring_area {
    height: 480px;
  }

  .alarm_label_row {
    display: flex;
  }

  .custom_textarea {
    background: #454d63;
    color: rgba(255, 255, 255, 0.5);
  }

  .people_list {
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 140px;

    .people_item {
      display: flex;
      margin-bottom: 20px;
      margin-right: 60px;

      .avatar {
        width: 52px;
        height: 52px;
        background: #30354d;
        border-radius: 50%;
        margin-right: 15px;

        img {
          width: 100%;
        }
      }

      .people_cell {
        color: rgba(255, 255, 255, 0.5);
        line-height: 24px;
      }
    }
  }

  .tool_row {
    padding: 20px 0;
    text-align: center;
    background: rgba(30, 30, 30, 0.9);

    .tool_btn {
      margin: 0 20px;
      width: 150px;
    }

    .tool_comfirn {
      background: rgba(15, 110, 190, 1);
      transition: background 0.2s ease-out;
      border: none;

      &:hover {
        background: rgba(15, 100, 160, 1);
      }
    }

    .tool_just {
      transition: background 0.2s ease-out;
      border: 1px solid rgba(74, 82, 107, 1);
      background: transparent;
      color: rgba(255, 255, 255, 0.8);

      &:hover {
        color: #fff;
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

.el-switch__label {
  color: #ffffff !important;
}

.el-textarea.is-disabled .el-textarea__inner {
  background-color: #454d63 !important;
}
</style>
