/*
 * @Author: ch3nh2
 * @Date: 2021-06-02 11:01:19
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-03-16 16:28:30
 * @FilePath: /zhy_dataview_fe/src/utils/index.js
 * @Description: 工具函数
 */
import { ElMessageBox, ElLoading } from 'element-plus';
import router from '@/router/index';
import globalData from '@/utils/globalData';

const utils = {};
let loading;

/**
 * 求百分比
 * @param {*} num 当前数
 * @param {*} total 总数
 * @returns
 */
utils.getPercent = (num, total) => {
    num = parseFloat(num);
    total = parseFloat(total);
    if (isNaN(num) || isNaN(total)) {
        return 0;
    }
    return total <= 0 ? 0 : Math.round((num / total) * 10000) / 100.0;
};

/**
 * 时间转毫秒
 * @param {*} type 需要被转换的时间类型 天、时、分、秒(d, h, m, s)
 * @param {*} num 需要被转换的时间值
 */
utils.getTimeCount = (type, num) => {
    if (type == 'd') {
        return num * 86400 * 1000;
    }
    if (type == 'h') {
        return num * 3600 * 1000;
    }
    if (type == 'm') {
        return num * 60 * 1000;
    }
    if (type == 's') {
        return num * 1000;
    }
    return '-';
};

/**
 * 显示加载框
 * @param {*} options 
 */
utils.showLoading = (options) => {
    loading = ElLoading.service({
        lock: true,
        text: "请求中，请稍后...",
        background: "rgba(31, 31, 43, 0.9)",
        ...options
    });
}

/**
 * 隐藏加载框
 */
utils.hideLoading = () => {
    loading && loading.close()
}

/**
 * 获取当前月份的第一天
 */
utils.getCurrentMonthFirst = () => {
    var date = new Date();
    date.setDate(1);
    var month = parseInt(date.getMonth() + 1);
    var day = date.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return date.getFullYear() + '/' + month + '/' + day;
};

/**
 * 获取当前月份的最后一天
 */
utils.getCurrentMonthLast = () => {
    var date = new Date();
    var currentMonth = date.getMonth();
    var nextMonth = ++currentMonth;
    var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
    var oneDay = 1000 * 60 * 60 * 24;
    var lastTime = new Date(nextMonthFirstDay - oneDay);
    var month = parseInt(lastTime.getMonth() + 1);
    var day = lastTime.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return date.getFullYear() + '/' + month + '/' + day;
};

/**
 * 获取下个月的第一天
 */
utils.nextMonthFirstDay = () => {
    var time = new Date();
    var year = time.getFullYear();
    var month = time.getMonth() + 2;
    if (month > 12) {
        month = month - 12;
        year = year + 1;
    }
    var day = 1;
    if (day < 10) {
        day = '0' + day;
    }
    return year + '/' + month + '/' + day;
};

/**
 * 获取下个月的最后一天
 */
utils.nextMonthLastDay = () => {
    var time = new Date();
    var year = time.getFullYear();
    var month = time.getMonth() + 2;
    if (month > 12) {
        month = month - 12;
        year = year + 1;
    }
    var day = this.nextMonthDay(year, month);
    if (day < 10) {
        day = '0' + day;
    }
    return year + '/' + month + '/' + day;
};

/**
 * 获取+1天日期
 * @param {*} time 时间
 */
utils.addOneDayDate = (time) => {
    var date = new Date(time);
    date = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
    return date;
};

/**
 * 获取指定日期月份的第一天
 */
utils.getDateCurrentMonthFirst = (myDate) => {
    var date = new Date(myDate);
    date.setDate(1);
    var month = parseInt(date.getMonth() + 1);
    var day = date.getDate();
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    return date.getFullYear() + '/' + month + '/' + day;
};

/**
 * 获取指定日期下个月的第一天
 */
utils.getDateNextMonthFirstDay = (myDate) => {
    var time = new Date(myDate);
    var year = time.getFullYear();
    var month = time.getMonth() + 2;
    if (month > 12) {
        month = month - 12;
        year = year + 1;
    }
    var day = 1;
    if (day < 10) {
        day = '0' + day;
    }
    return year + '/' + month + '/' + day;
};

/**
 * 转换成时间戳
 */
utils.toTimestamp = (date) => {
    let myDate = new Date(date);
    let timestamp = myDate.getTime();
    return timestamp;
};

/**
 * 截取某个字符串
 * @param {string} str 需要截取的字符串
 * @param {number} sliceLen 需要截取的字符串下标
 */
utils.sliceStr = (str, sliceLen) => {
    if (!str) {
        return '';
    }
    let realLength = 0;
    const len = str.length;
    let charCode = -1;
    for (var i = 0; i < len; i++) {
        charCode = str.charCodeAt(i);
        if (charCode >= 0 && charCode <= 128) {
            realLength += 1;
        } else {
            realLength += 2;
        }
        if (realLength > sliceLen) {
            return `${str.slice(0, i)}`;
        }
    }

    return str;
};

/**
 * 截取时间字符串
 * @param {string} date 需要截取的时间串(如：2020-05-20 00:00:00)
 * @param {number} state 需要截取的部分(0 | 1)
 * @returns 返回一个截取后的日期或时间(2020-05-20 | 00:00:00)
 */
utils.getDate = (date, state) => {
    var index = date.lastIndexOf(' ');
    if (state == 0) {
        date = date.substring(0, index);
    } else {
        date = date.substring(index + 1, date.length);
    }
    return date;
};

/**
 * 获取url参数
 * @param {*} variable 需要获取的字段名
 * @param {*} url 需要获取的url
 */
utils.getQueryVariable = (variable, url) => {
    var query = decodeURIComponent(url);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
};

/**
 * 生成从minNum到maxNum的随机数
 * @param {[type]} minNum 最小值
 * @param {[type]} maxNum 最大值
 */
utils.randomNum = (minNum, maxNum) => {
    var Range = maxNum - minNum;
    var Rand = Math.random();
    if (Math.round(Rand * Range) == 0) {
        return minNum + 1;
    } else if (Math.round(Rand * maxNum) == maxNum) {
        return maxNum - 1;
    } else {
        var num = minNum + Math.round(Rand * Range) - 1;
        return num;
    }
};

/**
 * 获取今年第一天
 */
utils.getTheYear = () => {
    const theYear = `${new Date().getFullYear()}-01-01 00:00:00`;
    return new Date(theYear);
};

/**
 * 获取明年第一天
 */
utils.getNewYear = () => {
    const newYear = `${new Date().getFullYear() + 1}-01-01 00:00:00`;
    return new Date(newYear);
};

/**
 * 获取当前星期几
 */
utils.getWeekDate = () => {
    var now = new Date();
    var day = now.getDay();
    var weeks = new Array('星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六');
    var week = weeks[day];
    return week;
};

/**
 * 错误信息弹窗
 * @param {*} message 主内容
 * @param {*} title 标题
 */
utils.errorAlert = async (message, title = '错误提示', callback) => {
    globalData.set("errorAlert", true);
    await ElMessageBox.alert(message, title, {
        showCancelButton: false,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
        center: true,
        callback: callback && callback
    })
    globalData.del("errorAlert");
};

/**
 * 重新登录提醒
 */
utils.loginAlert = async () => {
    localStorage.removeItem('USER_INFO');
    localStorage.removeItem('ACCESS_TOKEN');
    await ElMessageBox.alert('登录信息已过期，请重新进行登录', '提示', {
        showCancelButton: false,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
        center: true,
    })
    router.replace('/login')
};

/**
 * 后端接口返回文件流处理
 */

utils.blobTransition = (res, fileName) => {
    const fileTypes = {
        '7z': 'application/x-7z-compressed',
        zip: 'application/zip',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        xls: 'application/vnd.ms-excel',
        xhtml: 'application/xhtml+xml',
        webp: 'image/webp',
        webm: 'video/webm',
        weba: 'audio/webm',
        wav: 'audio/wav',
        vsd: 'application/vnd.visio',
        txt: 'text/plain',
        tar: 'application/x-tar',
        swf: 'application/x-shockwave-flash',
        rar: 'application/x-rar-compressed',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        ppt: 'application/vnd.ms-powerpoint',
        pdf: 'application/pdf',
        ogx: 'application/ogg',
        ogv: 'video/ogg',
        oga: 'audio/ogg',
        mpeg: 'video/mpeg',
        mp3: 'audio/mpeg',
        json: 'application/json',
        png: 'image/png',
        jpeg: 'image/jpeg',
        jpg: 'image/jpeg',
        ico: 'image/vnd.microsoft.icon',
        gif: 'image/gif',
        bmp: 'image/bmp',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        doc: 'application/msword',
        csv: 'text/csv',
        bz2: 'application/x-bzip2',
        bz: 'application/x-bzip',
        avi: 'video/x-msvideo',
        arc: 'application/x-freearc',
        abw: 'application/x-abiword',
        aac: 'audio/aac',
    };
    const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
    if (fileTypes[fileExtension]) {
        const blob = new Blob([res], { type: fileTypes[fileExtension] });
        const objectUrl = URL.createObjectURL(blob);
        // window.open(objectUrl);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        alert('暂时不支持该类型，请联系开发人员');
    }
};

/**
 * 删除html标签
 */
utils.removeHtml = (str) => {
    return str.replace(/<[^>]*>|<\/[^>]*>/gm, '');
};

/**
 * setTimeOut Promise方式
 * @param {*} time 时间
 * @returns 
 */
utils.timeOut = (time) => {
    return new Promise((resolve) => {
        setTimeout(resolve, time);
    });
}

/**
 * 时间戳转换成几分钟前、几小时前等等...
 * @ timespan 时间戳
 * @ showBeyond 是否大于1小时的就要显示日期
 * */
utils.formatMsgTime = (timespan, notShowDatetime) => {
    let dateTime = new Date(timespan); // 将传进来的字符串或者毫秒转为标准时间
    let year = dateTime.getFullYear();
    let month = dateTime.getMonth() + 1;
    month = month < 10 ? '0' + month : month;
    let day = dateTime.getDate();
    day = day < 10 ? '0' + day : day;
    let hour = dateTime.getHours();
    hour = hour < 10 ? '0' + hour : hour;
    let minute = dateTime.getMinutes();
    minute = minute < 10 ? '0' + minute : minute;
    let second = dateTime.getSeconds();
    second = second < 10 ? '0' + second : second;
    let millisecond = dateTime.getTime(); // 将当前编辑的时间转换为毫秒
    let now = new Date(); // 获取本机当前的时间
    let nowNew = now.getTime(); // 将本机的时间转换为毫秒
    let milliseconds = 0;
    let timeSpanStr;
    milliseconds = nowNew - millisecond;
    if (milliseconds <= 1000 * 60 * 1) {
        // 小于一分钟展示为刚刚
        timeSpanStr = '刚刚';
    } else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) {
        // 大于一分钟小于一小时展示为分钟
        timeSpanStr = Math.round(milliseconds / (1000 * 60)) + '分钟前';
    } else if (notShowDatetime && 1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) {
        // 大于一小时小于一天展示为小时
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + '小时前';
    } else if (notShowDatetime && 1000 * 60 * 60 * 24 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24 * 15) {
        // 大于一天小于十五天展示为天
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + '天前';
    } else if (notShowDatetime && milliseconds > 1000 * 60 * 60 * 24 * 15 && year === now.getFullYear()) {
        timeSpanStr = month + '-' + day + ' ' + hour + ':' + minute;
    } else {
        timeSpanStr = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
    }
    return timeSpanStr;
};

/***
 *  反推树结构，查找原始数据
 * */
utils.treeFindOids = (tree, ids) => {
    ids = typeof ids === 'string' ? JSON.parse(ids) : ids
    if (ids === null || ids.length === 0) {
        return [];
    }
    const arr = [];
    const oIds = [];
    const expanded = (datas) => {
        if (datas && datas.length > 0) {
            datas.forEach((e) => {
                arr.push(e);
                expanded(e.childNodeList);
            });
        }
    };
    expanded(tree);

    // 把平铺的数组转为对象去查找
    const arrObj = {};
    arr.forEach((item) => {
        arrObj[item.id] = item;
    });
    ids.forEach((item) => {
        arrObj[item] && oIds.push(arrObj[item]);
    });
    return oIds;
};

/**
* URL参数处理
* @param {*} params  参数
*/
utils.tansParams = (params) => {
    let result = '';
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result;
};

/**
* 滚轮控制元素横向滚动事件类
* @param {HTMLElement} el 处理的元素
* @return {Object} 事件类实例 { el: 处理的元素, active: 事件是否已激活, add(smooth: 是否平滑滚动): 为元素添加事件, remove(): 为元素解绑事件 }
*/
utils.WheelDirective = (function () {
    var eventAdd = 'addEventListener' in window ? 'addEventListener' : 'attachEvent'
    var eventRemove = 'removeEventListener' in window ? 'removeEventListener' : 'detachEvent'
    var eventName = 'onmousewheel' in document ? 'mousewheel' : 'onwheel' in document ? 'wheel' : 'attachEvent' in window ? 'onmousewheel' : 'DOMMouseScroll'
    var eventFN = function (e) {
        e.preventDefault()
        if (this.clientWidth >= this.scrollWidth) return
        this.scrollLeft += e.deltaY || e.detail || -e.wheelDelta || 0
    }
    return function (el) {
        var instance = {
            el, active: false,
            add: function (smooth) {
                el[eventAdd](eventName, eventFN, { passive: false })
                if (smooth) el.style.scrollBehavior = 'smooth'
                instance.active = true
            },
            remove: function () {
                el[eventRemove](eventName, eventFN)
                instance.active = false
            }
        }
        return instance
    }
})();

export default utils;
