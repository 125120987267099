/*
 * @Author: ch3nh2
 * @Date: 2021-06-09 18:54:07
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-09-07 17:48:26
 * @FilePath: /zhy_dataview_fe/src/utils/httpApi.js
 * @Description: none
 */
import request from "@/utils/request";
const api = {};

/**------------------------------------------用户相关---------------------------------------- **/

/**
 * 获取图像验证码
 * @param {*} 无
 */
api.getImgCode = () => {
  return request({
    url: "api/passport/pUser/imgCodeData",
    method: "get",
  });
};

/**
 * 用户登录
 * @param {*} 无
 */
api.userLogin = (data) => {
  return request({
    url: "api/passport/pUser/login",
    method: "post",
    data,
  });
};

/**
 * 用户登出
 * @param {*} 无
 */
api.logout = () => {
  return request({
    url: "api/passport/pUser/logout",
    method: "get",
  });
};

/**
 * 用户信息
 * @param {*} 无
 */
api.userInfo = (data) => {
  return request({
    url: "api/passport/pUser/userInfo",
    method: "post",
    data,
  });
};

/**
 * 用户列表
 * @param {*} 无
 */
api.userList = (params) => {
  return request({
    url: "api/admin/pUser/list",
    method: "get",
    params
  });
};

/**------------------------------------------会议相关---------------------------------------- **/

/**
 * 发起即时会议
 * @param {*} 无
 */
api.postMeetingNow = (data) => {
  return request({
    url: "api/platform/meeting/now",
    method: "post",
    data,
  });
};

/**
 * 添加一条参会记录
 * @param {*} 无
 */
api.addJoinRecord = (data) => {
  return request({
    url: "api/platform/callMeetingRecords/add",
    method: "post",
    data,
  });
};

/**
 * 查询当天参会记录
 * @param {*} 无
 */
api.getJoinRecords = (params) => {
  return request({
    url: "api/platform/callMeetingRecords/pageList",
    method: "get",
    params,
  });
};

/**
 * 查询快捷会议室列表
 * @param {*} 无
 */
api.getShortcutRooms = () => {
  return request({
    url: "api/platform/meeting/getShortcutRooms",
    method: "get",
  });
};

/**
 * 查询单个快捷会议室详情
 * @param {*} 无
 */
api.getRoomDetail = (params) => {
  return request({
    url: "api/platform/meeting/getRoomDetail",
    method: "get",
    params,
  });
};

/**
 * 快捷会议室详情
 * @param {roomId} 会议房间
 */
api.getRoomDetail = (roomId) => {
  console.log("🚀 ~ file: httpApi.js ~ line 126 ~ roomId", roomId);
  return request({
    url: "api/platform/meeting/getRoomDetail?roomId=" + roomId,
    method: "get",
  });
};

/**------------------------------------------通知消息相关---------------------------------------- **/

/**
 * 新增通知消息
 * @param {*} 无
 */
api.addNotice = (data) => {
  return request({
    url: "api/platform/noticeMessage/message/add",
    method: "post",
    data,
  });
};

/**
 * 获取通知消息列表
 * @param {*} 无
 */
api.getNoticeList = (params) => {
  return request({
    url: "api/platform/noticeMessage/message/list",
    method: "get",
    params,
  });
};

/**
 * 查看通知消息详情
 * @param {*} 无
 */
api.getNoticeInfo = (params) => {
  return request({
    url: `api/platform/noticeMessage/info/${params.id}`,
    method: "get"
  });
};

/**
 * 取消发布
 * @param {*} 无
 */
api.cancelNotice = (params) => {
  return request({
    url: `api/platform/noticeMessage/message/cancel/${params.id}`,
    method: "put"
  });
};

/**------------------------------------------通讯相关---------------------------------------- **/

/**
 * 获取通讯录列表（树状）
 * @param {*} 无
 */
api.getTreeContacts = (params) => {
  return request({
    url: "api/platform/contact/getTreeContacts",
    method: "get",
    params,
  });
};

/**
 * 获取通讯录列表
 * @param {*} 无
 */
api.getAllContact = (params) => {
  return request({
    url: "api/platform/contact/searchContacts/2",
    method: "get",
    params,
  });
};

/**
 * 查询快捷联系人列表
 * @param {*} 无
 */
api.getFavContacts = (params) => {
  return request({
    url: "api/platform/contact/getQuickContacts/2",
    method: "get",
    params,
  });
};

/**
 * 添加快捷联系人
 * @param {*} 无
 */
api.addFavContacts = (data) => {
  return request({
    url: "api/platform/contact/setQuickContacts",
    method: "post",
    data,
  });
};

/**
 * 删除快捷联系人
 * @param {*} 无
 */
api.delFavContacts = (data) => {
  return request({
    url: "api/platform/contact/deleteQuickContacts",
    method: "post",
    data,
  });
};

/**
 * 设置默认拨打方式
 * @param {*} 无
 */
api.setDefaultCall = (params) => {
  return request({
    url: "api/platform/contact/set-default-call",
    method: "get",
    params,
  });
};

/**
 * 搜索通讯录
 * @param {*} 无
 */
api.fineContacts = (params) => {
  return request({
    url: "api/platform/contact/find-by-key",
    method: "get",
    params,
  });
};

/**
 * 获取视频监控设备列表
 * @param {*} 无
 */
api.getVideoDeviceContact = (data) => {
  return request({
    url: "api/platform/contact/get-video-device-contact",
    method: "get",
    data,
  });
};

/**------------------------------------------上传下载---------------------------------------- **/

/**
 * 文件上传
 * @param {string} file 文件
 */
api.uploadFile = (data) => {
  return request({
    url: "api/platform/file/upload",
    method: "post",
    data,
  });
};

/**
 * 文件下载
 * @param {file} fileName 文件名
 */
api.downloadFile = (params, other) => {
  return request({
    url: "api/platform/file/download",
    method: "get",
    params,
    ...other,
  });
};

/**------------------------------------------电话拨打---------------------------------------- **/

/**
 * 电话拨打
 * @param {*} 无
 */
api.postPhone = (data) => {
  return request({
    url: "api/platform/phone/tel",
    method: "post",
    data,
  });
};

/**
 * 按免提并拨打电话
 * @param {*} 无
 */
api.handFreeAndCall = (data) => {
  return request({
    url: "api/platform/phone/handFreeAndCall",
    method: "post",
    data,
  });
};

/**
 * 按电话的免提键
 * @param {*} 无
 */
api.actionHandFree = () => {
  return request({
    url: "api/platform/phone/actionHandFree",
    method: "get",
  });
};

/**
 * 接电话
 * @param {*} 无
 */
api.actionAnswer = () => {
  return request({
    url: "api/platform/phone/actionAnswer",
    method: "get",
  });
};

/**
 * 按免提并且接电话
 * @param {*} 无
 */
api.actionHandFreeAndAnswer = () => {
  return request({
    url: "api/platform/phone/actionHandFreeAndAnswer",
    method: "get",
  });
};

/**
 * 结束通话
 * @param {*} 无
 */
api.actionEndCall = () => {
  return request({
    url: "api/platform/phone/actionEndCall",
    method: "get",
  });
};

/**
 * 拒接来电
 * @param {*} 无
 */
api.actionRefuseCall = () => {
  return request({
    url: "api/platform/phone/actionRefuseCall",
    method: "get",
  });
};

/**-----------------------------------------对讲机拨打--------------------------------------- **/

/**
 * 一键拨打对讲机号（该接口限速，5秒只能执行一次）
 * @param {*} 无
 */
api.simpleTalkie = (data) => {
  return request({
    url: "api/platform/phone/simpleTalkie",
    method: "post",
    data
  });
};

/**------------------------------------------事件日志---------------------------------------- **/

/**
 * 大屏首页事件日志信息(大屏首页最新的几条事件日志信息)
 * */
api.getSnapshot = (params) => {
  return request({
    url: "api/platform/index/eventMessage/snapshot",
    method: "get",
    params,
  });
};

/**
 * 大屏首页事件日志列表 更多内容部分
 * */
api.getEventMessageList = (params) => {
  return request({
    url: "api/platform/index/eventMessage/list",
    method: "get",
    params,
  });
};

/**
 * 大屏首页事件日志列表 更多事件日志展开
 * */
api.getEventMessageMore = (params) => {
  return request({
    url: "api/platform/index/eventMessage/today",
    method: "get",
    params,
  });
};

/**
 * 事件日志详情查看接口
 * */
api.getEventMessageRead = (params) => {
  return request({
    url: "api/platform/index/eventMessage/read",
    method: "get",
    params,
  });
};

/**
 * 获取告警联动事件弹窗
 * */
api.getLinkagePopup = () => {
  return request({
    url: "api/platform/alert/executeRecord/list",
    method: "get",
  });
};

/**
 * 获取事件日志刷新频率
 */
api.getLogTime = () => {
  return request({
    url: "api/platform/index/flush/get",
    method: "get",
  });
};

/**------------------------------------------水文气象---------------------------------------- **/

/**
 * 大屏首页气象数据接口
 * */
api.getHomeWeather = () => {
  return request({
    url: "api/platform/meteoro/hydr",
    method: "get",
  });
};

/**
 * 水文气象详情列表页
 * */
api.getWeatherDetails = (params) => {
  return request({
    url: "api/platform/meteoro/hydr/list",
    method: "get",
    params,
  });
};

/**
 * 五要素实时数据
 * */
api.getLiveFiveData = () => {
  return request({
    url: "api/platform/meteoro/realtime-five-hydr-data",
    method: "get",
  });
}

/**
 * 五要素实时数据（指定日期）
 * */
api.getLiveFiveDataTrend = (params) => {
  return request({
    url: "api/platform/meteoro/five-realtime-trend",
    method: "get",
    params
  });
}

/**
 * 十二要素实时数据
 * */
api.getLiveTwelveData = () => {
  return request({
    url: "api/platform/meteoro/realtime-hydr-data",
    method: "get"
  });
}

/**
 * 十二要素实时数据（指定日期）
 * */
api.getLiveTwelveDataTrend = (params) => {
  return request({
    url: "api/platform/meteoro/realtime-trend",
    method: "get",
    params
  });
}

/**------------------------------------------广播相关---------------------------------------- **/

/**
 * 获取广播区域接口
 * */
api.getAreaIds = () => {
  return request({
    url: "api/platform/broadcast/getAreaIds",
    method: "get",
  });
};

/**
 * 从itc系统同步广播区域到系统表中
 */
api.broadcastSyncAreaIds = (params) => {
  return request({
    url: 'api/admin/broadcast/syncAreaIds',
    method: 'get',
    params,
  });
};

export default api;
