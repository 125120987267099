/*
 * @Author: ch3nh2
 * @Date: 2021-06-16 16:48:43
 * @LastEditors: ch3nh2
 * @LastEditTime: 2021-10-21 19:20:14
 * @FilePath: /zhy_dataview_fe/src/plugins/globalData.js
 * @Description: none
 */
import globalData from '@/utils/globalData';

export default (app) => {
  app.config.globalProperties.$globalData = globalData
}