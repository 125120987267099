/*
 * @Author: ch3nh2
 * @Date: 2021-06-09 15:46:12
 * @LastEditors: ch3nh2
 * @LastEditTime: 2021-10-21 19:20:25
 * @FilePath: /zhy_dataview_fe/src/plugins/httpRequest.js
 * @Description: none
 */
import request from '@/utils/request';

export default (app) => {
  app.config.globalProperties.$http = request
}