<!--
 * @Author: ch3nh2
 * @Date: 2021-10-25 18:25:19
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-10-20 22:13:37
 * @FilePath: /zhy_dataview_fe/src/components/ThreeD.vue
 * @Description: 3D建模
-->
<template>
  <div class="three-d">
    <div class="VideoPlayerList VideoPlayerListLeftSide">
      <div
        class="videoDialog homeBigScreen"
        v-for="(item, index) in videoSourceList.leftSide"
        :key="index"
      >
        <i
          class="el-drawer__close el-icon el-icon-close close_big_screen"
          @click="clickCloseCamera(index, 'leftSide')"
        />
        <VideoPlayer :source="item" />
      </div>
    </div>
    <div class="VideoPlayerList VideoPlayerListRightSide">
      <div
        class="videoDialog homeBigScreen"
        v-for="(item, index) in videoSourceList.rightSide"
        :key="index"
      >
        <i
          class="el-drawer__close el-icon el-icon-close close_big_screen"
          @click="clickCloseCamera(index, 'rightSide')"
        />
        <VideoPlayer :source="item" />
      </div>
    </div>
    <iframe class="iframe" src="./3d/index.html" frameborder="0" />
  </div>
</template>

<script>
import VideoPlayer from "./InsidePages/VideoPlayer";
import { getCameraList } from "@/api/hydrometeor/index.js";
export default {
  name: "ThreeD",
  data() {
    return {
      BigScreenVisible: false,
      bigScreenX: 0,
      bigScreenY: 0,
      dataurl: "",
      // 摄像头列表
      videoSourceList: { leftSide: [], rightSide: [] },
    };
  },

  components: {
    VideoPlayer,
  },

  mounted() {
    window.clickCamera = this.clickCamera; //点击摄像头
    window.goDetail = this.goDetail;
    window.monitordata = this.monitordatafun;
  },

  methods: {
    // 点击模型中的摄像头
    clickCamera(flvUrl, event) {
      const { x } = event || {};
      const objKey = x >= 0 ? "rightSide" : "leftSide";
      // 点击打开摄像头条件限制
      if (this.openCameraRules(flvUrl, objKey) === false) return;
      // push到摄像头列表数据
      this.videoSourceList[objKey].push(flvUrl);
    },

    // 点击打开摄像头条件限制
    openCameraRules(flvUrl, objKey) {
      let key;
      for (key in this.videoSourceList) {
        if (this.videoSourceList[key].indexOf(flvUrl) != -1) {
          const position = key == "rightSide" ? "右侧" : "左侧"; //位置
          this.$message({
            message: `当前摄像头已打开在${position}`,
            type: "warning",
          });
          return false;
        }
      }
      if (this.videoSourceList[objKey].length == 5) {
        this.$message({
          message: "一侧最多只能查看5个摄像头",
          type: "warning",
        });
        return false;
      }
    },

    // 点击关闭摄像头的icon
    clickCloseCamera(index, key) {
      // 在摄像头列表中删除对应索引的数据
      this.videoSourceList[key].splice(index, 1);
    },

    goDetail() {
      this.$router.push("/monitoring/detail");
    },

    // 获取监控摄像头数据（给嵌套index.html 3d页面调用）
    monitordatafun() {
      return new Promise((resolve) => {
        getCameraList({ page: 1, pageSize: 99 }).then((res) => {
          const { cameraList = [] } = res && res.data ? res.data : {};
          resolve(cameraList);
        });
      });
    },
  },
};
</script>

<style lang="scss">
.three-d {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);

  .iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 16px;
  }

  .VideoPlayerList {
    z-index: 99;
    position: absolute;
    top: 99px;

    .homeBigScreen {
      position: relative;
      margin-top: 24px;
      width: 250px;
      height: 150px;
      background: #1f1f2b;

      &:first-child {
        margin-top: 0;
      }

      .close_big_screen {
        position: absolute;
        right: 0;
        top: 0px;
        z-index: 99;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .VideoPlayerListLeftSide {
    left: 442px;
  }

  .VideoPlayerListRightSide {
    right: 441px;
  }
}
</style>
