/*
 * @Author: ch3nh2
 * @Date: 2021-06-09 19:10:18
 * @LastEditors: ch3nh2
 * @LastEditTime: 2021-10-21 19:20:21
 * @FilePath: /zhy_dataview_fe/src/plugins/httpApi.js
 * @Description: none
 */
import api from '@/utils/httpApi';

export default (app) => {
  app.config.globalProperties.$api = api
}