/*
 * @Author: ch3nh2
 * @Date: 2021-06-09 15:00:17
 * @LastEditors: ch3nh2
 * @LastEditTime: 2023-05-15 10:28:11
 * @FilePath: /zhy_dataview_fe/src/config/index.js
 * @Description: 环境变量
 */
const config = {};
const href = window.location.href;
const env = process.env.NODE_ENV;
const dev = env == "development" && "DEV"; //本地开发环境（勿动）
const sit = env == "production" && (/xhangjia/.test(href) || /ceshi200/.test(href)) && "SIT"; //线上内测环境（勿动）
const prod = env == "production" && (/192.168/.test(href) || /10.132/.test(href)) && "PROD"; //海油生产环境（勿动）
const consoleLog = dev || sit ? true : false;

//后端接口地址（修改对应）
const baseObj = {
	PROD: "//192.168.1.100",
	DEV: "//zhy.ceshi200.com",
	SIT: "//api-zhy-dataview.xhangjia.com",
};

//前端管理地址（修改对应）
const adminObj = {
	PROD: "//192.168.1.100:81",
	DEV: "//127.0.0.1:8081",
	SIT: "//zhy-admin.xhangjia.com",
};

//m7s云台控制地址（修改对应）
const m7sObj = {
	PROD: "//10.132.198.206:8081",
	DEV: "//127.0.0.1:8081",
	SIT: "//admin-zhy.ceshi200.com",
};

//亿联接口地址（修改对应）
const yealinkUrlObj = {
	PROD: "//192.168.1.200",
	DEV: "//umedemo.yealinkops.com",
	SIT: "//umedemo.yealinkops.com",
};

//亿联配置域名（修改对应）
const yealinkRealmObj = {
	PROD: "test.cnooc.com",
	DEV: "umedemo.yealinkops.com",
	SIT: "umedemo.yealinkops.com",
};

// webSocket地址（修改对应）
const socketObj = {
	PROD: "ws://10.132.198.206/api/wsocket/1",
	DEV: "wss://api-zhy-dataview.xhangjia.com/api/wsocket/1",
	SIT: "wss://api-zhy-dataview.xhangjia.com/api/wsocket/1",
};

config.href = href;
config.env = env;
config.dev = dev;
config.sit = sit;
config.prod = prod;
config.consoleLog = consoleLog;
config.baseUrl = baseObj[dev || sit || prod];
config.adminUrl = adminObj[dev || sit || prod];
config.m7sUrl = m7sObj[dev || sit || prod];
config.socketUrl = socketObj[dev || sit || prod];
config.yealinkUrl = yealinkUrlObj[dev || sit || prod];
// config.yealinkRealm = yealinkRealmObj[dev || sit || prod].replace(/\//g, "");

export { config };
