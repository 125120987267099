/*
 * @Author: Leif
 * @Date: 2021-12-13 09:25:49
 * @LastEditTime: 2022-10-14 14:07:36
 * @LastEditors: ch3nh2
 * @Description: 
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vConsole from "vconsole";
import installElementPlus from "@/plugins/element";
import installApexCharts from "@/plugins/apexcharts";
import loadGlobalVar from "@/plugins/globalVar";
import loadHttpRequest from "@/plugins/httpRequest";
import loadHttpApi from "@/plugins/httpApi";
import loadJiaXun from "@/plugins/jiaxun";
import loadUtils from "@/plugins/utils";
import loadGlobalData from "@/plugins/globalData";

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	next();
});

const app = createApp(App).use(store).use(router);
installElementPlus(app);
installApexCharts(app);
loadGlobalVar(app);
loadHttpRequest(app);
loadHttpApi(app);
loadJiaXun(app);
loadUtils(app);
loadGlobalData(app);
app.mount("#app");
// new vConsole();