/*
 * @Author: Leif
 * @Date: 2021-12-13 09:37:51
 * @LastEditTime: 2022-04-15 20:34:47
 * @LastEditors: ch3nh2
 * @Description:
 */
import request from "@/utils/request";

export function meteorovisibility(params) {
	return request({
		url: "api/platform/meteoro/tempe-humidity-wind-visibility-trend",
		method: "get",
		params: params,
	});
}

export function meteorotype(params) {
	return request({
		url: `api/platform/meteoro/getAlertSubTypeStatisticsData?alertSubTypeList%5B0%5D=TEMPERATURE&alertSubTypeList%5B1%5D=WIND_SPEED&alertSubTypeList%5B2%5D=HUMIDITY`,
		method: "get",
		params,
	});
}

export function meteorolist(params) {
	return request({
		url: "api/platform/meteoro/hydr/list",
		method: "get",
		params: params,
	});
}

export function meteoroweekday(params) {
	return request({
		url: "api/platform/meteoro/detail-of-weekday",
		method: "get",
		params: params,
	});
}

export function meteororealtime(params) {
	return request({
		url: "api/platform/meteoro/realtime-trend",
		method: "get",
		params: params,
	});
}

export function meteororealfivedata() {
	return request({
		url: "api/platform/meteoro/realtime-five-hydr-data",
		method: "get",
	});
}

export function meteororealdata() {
	return request({
		url: "api/platform/meteoro/realtime-hydr-data",
		method: "get",
	});
}

export function meteororatio(params) {
	return request({
		url: "api/platform/meteoro/special-weather-ratio",
		method: "get",
		params: params,
	});
}

export function meteorohomehydr() {
	return request({
		url: "api/platform/meteoro/hydr",
		method: "get",
	});
}

//接电话
export function actionAnswer() {
	return request({
		url: "/api/platform/phone/actionAnswer",
		method: "get",
	});
}

//拒接电话
export function actionRefuseCall() {
	return request({
		url: "/api/platform/phone/actionRefuseCall",
		method: "get",
	});
}

//挂断电话
export function actionEndCall() {
	return request({
		url: "/api/platform/phone/actionEndCall",
		method: "get",
	});
}

//告警弹窗
export function getLinkagePopup(params) {
	return request({
		url: "/api/platform/alert/executeRecord/list",
		method: "get",
		params
	});
}

//确认告警弹窗
export function postConfirmWarn(data) {
	return request({
		url: "/api/platform/alert/executeRecord/pass",
		method: "post",
		data
	});
}

//忽略告警联动弹窗
export function removeWarn(id, data) {
	return request({
		url: "/api/platform/alert/executeRecord/notPass?id=" + id,
		method: "post",
		data
	});
}

//云台镜头控制
export function operateCamera(params) {
	return request({
		url: "/api/platform/icc/operate-camera",
		method: "post",
		data: params,
	});
}

//云台方向控制
export function operateDirect(params) {
	return request({
		url: "/api/platform/icc/operate-direct",
		method: "post",
		data: params,
	});
}

//获取监控设备列表
export function getCameraList(params) {
	return request({
		url: "/api/platform/camera/config",
		method: "get",
		params: params,
	});
}
