/*
 * @Author: ch3nh2
 * @Date: 2021-06-09 15:20:38
 * @LastEditors: ch3nh2
 * @LastEditTime: 2021-10-21 19:20:18
 * @FilePath: /zhy_dataview_fe/src/plugins/globalVar.js
 * @Description: none
 */
import { config } from '@/config/index';

export default (app) => {
  app.config.globalProperties.$config = config
  app.config.globalProperties.$cdnUrl = config.cdnUrl
  app.config.globalProperties.$thisCdnUrl = config.thisCdnUrl
}